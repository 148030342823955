import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { UserProvider } from './components/auth/UserContext';
import styled, { createGlobalStyle } from 'styled-components';
import SignUp from './components/auth/SignUp';
import Login from './components/auth/Login';
import Header from './components/dashboards/Header';
import AdminDashboard from './components/dashboards/AdminDashboard';
import SupervisorDashboard from './components/dashboards/SupervisorDash';
import JobForm from './components/Services Logic/JobForm';
import JobList from './components/Services Logic/Job List/JobList';
import ManagerDashboard from './components/dashboards/ManagerDash9';
import JobDetails from './components/Services Logic/JobDetails';
import ErrorBoundary from './components/Helper Functions/ErrorBoundary';
import SupervisorServiceList from './components/Services Logic/SupervisorServicesList';
import ManagerJobForm from './components/Services Logic/ManagerJob.Form';
import './i18n/i18n';

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
`;

const Footer = styled.footer`
  background-color: #296b1a;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

// ProtectedRoute component definition
const ProtectedRoute = ({ children, allowedRoles }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && (!currentUser || !allowedRoles.includes(currentUser.role))) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  const handleSignUp = () => {
    setIsLoggedIn(true);
  };

  const handleLogin = (user) => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('isAdmin', user?.isAdmin || false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isAdmin');
  };

  return (
    <Router>
      <ErrorBoundary>
        <UserProvider>
          <AppContainer>
            <GlobalStyle />
            <Header isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/sign-up" element={<SignUp onSignUp={handleSignUp} />} />

              <Route
                path="/job-form"
                element={
                  <ProtectedRoute allowedRoles={['administrator', 'manager', 'supervisor']}>
                    <JobForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/job-list"
                element={
                  <ProtectedRoute>
                    <JobList />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/jobs/:id"
                element={
                  <ProtectedRoute allowedRoles={['manager', 'supervisor']}>
                    <JobDetails />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['administrator']}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manager-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['manager']}>
                    <ManagerDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manager-job-form"
                element={
                  <ProtectedRoute allowedRoles={['manager']}>
                    <ManagerJobForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/supervisor-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['supervisor']}>
                    <SupervisorDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/supervisor-job-list"
                element={
                  <ProtectedRoute allowedRoles={['supervisor']}>
                    <SupervisorServiceList />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AppContainer>
        </UserProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
