import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { auth, db } from '../Firebase Functions/firebase';
import { doc, getDoc } from 'firebase/firestore';
import RegisterEmployee from '../auth/RegisterEmployee';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column; /* Change to column for mobile */
  height: 100vh;

  @media (min-width: 768px) {
    flex-direction: row; /* Row layout for larger screens */
  }
`;

const Sidebar = styled.div`
  width: 100%; /* Full width on mobile */
  background-color: #adb3ad;
  color: white;
  padding: 20px;

  @media (min-width: 768px) {
    width: 200px; /* Fixed width on larger screens */
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  background-color: #ecf0f1;

  @media (max-width: 768px) {
    padding: 10px; /* Less padding on mobile */
  }
`;

const SidebarButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #296b1a;
  color: white;
  border: none;
  border-radius: 4px;
  
  &:hover {
    background-color: #2980b9;
  }
`;

const AdminDashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeComponent, setActiveComponent] = useState('home');
  const navigate = useNavigate();

  // Memoize the `checkAdminStatus` function
  const checkAdminStatus = useCallback(async () => {
      const user = auth.currentUser;
      if (user) {
          const userDoc = await getDoc(doc(db, "Users", user.uid));
          if (userDoc.exists() && userDoc.data().type === 'administrator') {
              setIsAdmin(true);
          } else {
              navigate('/home'); // Redirect non-admin users
          }
      } else {
          navigate('/login'); // Redirect to login if not authenticated
      }
  }, [navigate]); // Add `navigate` as a dependency

  useEffect(() => {
      checkAdminStatus();
  }, [checkAdminStatus]); // Include `checkAdminStatus` in the dependency array

  const renderComponent = () => {
      switch (activeComponent) {
          case 'registerEmployee':
              return <RegisterEmployee />;
          case 'manageEmployees':
              return <h2>Manage Employees (To be implemented)</h2>;
          case 'reports':
              return <h2>Reports (To be implemented)</h2>;
          default:
              return <h2>Welcome to Admin Dashboard</h2>;
      }
  };

  if (!isAdmin) {
      return <p>Loading...</p>; // Or a more sophisticated loading component
  }

   return (
     <DashboardContainer>
       <Sidebar>
         <h2>Admin Dashboard</h2>
         <SidebarButton onClick={() => setActiveComponent('registerEmployee')}>Register Employee</SidebarButton>
         <SidebarButton onClick={() => setActiveComponent('manageEmployees')}>Manage Employees</SidebarButton>
         <SidebarButton onClick={() => setActiveComponent('reports')}>Reports</SidebarButton>
       </Sidebar>
       <MainContent>
         {renderComponent()}
       </MainContent>
     </DashboardContainer>
   );
};

export default AdminDashboard;