import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
    font-size: 16px;
  }
`;

const AddServiceUpdateButton = styled(StyledButton)``;
const SeeServiceUpdatesButton = styled(StyledButton)``;

// New styled title component
const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const SupervisorDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="supervisor-dashboard">
      <StyledTitle>{t('Supervisor Dashboard')}</StyledTitle>
      <ButtonContainer>
        <AddServiceUpdateButton type="button" onClick={() => navigate('/job-form')}>
          {t('Submit New Service Update')}
        </AddServiceUpdateButton>
        <SeeServiceUpdatesButton type="button" onClick={() => navigate('/supervisor-job-list')}>
          {t('View Submitted Service Updates')}
        </SeeServiceUpdatesButton>
      </ButtonContainer>
    </div>
  );
};

export default SupervisorDashboard;
