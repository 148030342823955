import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase Functions/firebase';
import styled from 'styled-components';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2rem;
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const SignUpInput = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const SignUpButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;


const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`;

const RegisterEmployee = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [type] = useState('employee');
  const [setIsAdmin] = useState(false);

  // Memoize `checkAdminStatus` using `useCallback`
  const checkAdminStatus = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDoc(doc(db, "Users", user.uid));
      if (userDoc.exists() && userDoc.data().type === 'administrator') {
        setIsAdmin(true);
      } else {
        navigate('/home'); // Redirect non-admin users
      }
    } else {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [navigate, setIsAdmin]); // Add relevant dependencies

  useEffect(() => {
    checkAdminStatus();
  }, [checkAdminStatus]); // Include `checkAdminStatus` in the dependency array

  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    if (shouldNavigate) {
      console.log('Navigating to admin dashboard...');
      navigate('/admin');
    }
  }, [shouldNavigate, navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const adminUser = auth.currentUser;

      if (!adminUser) {
        setError('Admin not authenticated. Please log in again.');
        navigate('/login');
        return;
      }

      // Create new user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = userCredential.user;

      // Add user data to Firestore
      await setDoc(doc(db, "Users", newUser.uid), {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        type: type
      });

      setSuccessMessage('Employee registered successfully!');
      setShouldNavigate(true);

      // Navigate to AdminDashboard after successful registration
      setTimeout(() => {
        console.log('Navigating to admin dashboard...');
        navigate('/admin');
      }, 100);
    } catch (error) {
      setError('Failed to register employee. ' + error.message);
    }
  };

  return (
    <CenterContainer>
    <SignUpForm onSubmit={handleRegister}>
      <SignUpContainer>
        <h2>Register New Employee</h2>
        <SignUpInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <SignUpInput
          type="tel"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        

        <SignUpButton type="submit">Register Employee</SignUpButton>
    
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </SignUpContainer>
    </SignUpForm>
    </CenterContainer>
  );
};

export default RegisterEmployee;