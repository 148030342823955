import styled from 'styled-components';
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../auth/UserContext';
import { useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa'; // Import question mark icon

// Styled Components
const HeaderContainer = styled.header`
  background-color: #296b1a;
  color: white;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;

  @media (max-width: 768px) {
    padding: 0.5rem;
    flex-direction: column;
    min-height: auto;
  }
`;

const LogoTitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 40px;

  @media (max-width: 768px) {
    height: 30px;
    margin-bottom: 0.5rem;
  }
`;

const HeaderTitle = styled.h1`
  margin: 0 0 0 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  color: #f1f1f1;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 3px;
    background-color: #e74c3c;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.5rem;
  }
`;

const HeaderButton = styled.button`
  background-color: #ffffff;
  color: #296b1a;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: #e8f5e9;
  }
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffffff;
  }

  svg {
    color: #ffffff;
    width: 24px;
    height: 24px;
    transition: color 0.3s ease;
  }

  &:hover svg {
    color: #296b1a;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const FormControl = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #f9f9f9;

  &:focus {
    border-color: #007820;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 120, 32, 0.3);
  }

  & + & {
    margin-top: 1rem;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #f9f9f9;
  height: 2.8rem; /* Matches the height of the input fields */

  &:focus {
    border-color: #007820;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 120, 32, 0.3);
  }

  resize: none; /* Prevent resizing */
`;

const ModalLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
`;

const InstructionText = styled.p`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #4a5568;
  font-weight: 500;
  line-height: 1.5;
`;

const ModalButtons = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const PrimaryButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #007820;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 120, 32, 0.2);
  margin-left: 1rem;

  &:hover:not(:disabled) {
    background-color: #005c18;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 120, 32, 0.3);
  }

  &:disabled {
    background-color: #88b794;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const SecondaryButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #d1d5db;
  color: #333;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #b8bbc2;
  }
`;

const TRANSLATIONS_EN = {
  email: {
    requestAssistance: "Request Assistance",
    instructions: 'Please type your questions in the "Message" section below. Our team will respond to you promptly.',
    subject: "Subject",
    subjectPlaceholder: "Enter subject",
    message: "Message",
    messagePlaceholder: "Enter your message",
    close: "Close",
    send: "Send Email",
    sentSuccessfully: "Email sent successfully!",
    failedToSend: "Failed to send email",
    errorSending: "Error sending email"
  }
};

// EmailModal Component
const EmailModal = ({ onClose }) => {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState({
    to: 'eporter@rotoloconsultants.com',
    subject: '',
    text: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailData }),
      });
      if (response.ok) {
        alert(t('email.sentSuccessfully', TRANSLATIONS_EN.email.sentSuccessfully));
        onClose();
      } else {
        const error = await response.json();
        alert(`${t('email.failedToSend', TRANSLATIONS_EN.email.failedToSend)}: ${error.error}`);
      }
    } catch (err) {
      console.error(t('email.errorSending', TRANSLATIONS_EN.email.errorSending), err);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>{t('email.requestAssistance', TRANSLATIONS_EN.email.requestAssistance)}</h2>
        <InstructionText>
          {t('email.instructions', TRANSLATIONS_EN.email.instructions)}
        </InstructionText>
        <form onSubmit={handleSubmit}>
          <div>
            <ModalLabel>{t('email.subject', TRANSLATIONS_EN.email.subject)}:</ModalLabel>
            <FormControl
              type="text"
              name="subject"
              value={emailData.subject}
              onChange={handleChange}
              placeholder={t('email.subjectPlaceholder', TRANSLATIONS_EN.email.subjectPlaceholder)}
            />
          </div>
          <div>
            <ModalLabel>{t('email.message', TRANSLATIONS_EN.email.message)}:</ModalLabel>
            <Textarea
              name="text"
              value={emailData.text}
              onChange={handleChange}
              placeholder={t('email.messagePlaceholder', TRANSLATIONS_EN.email.messagePlaceholder)}
            />
          </div>
          <ModalButtons>
            <SecondaryButton type="button" onClick={onClose}>
              {t('email.close', TRANSLATIONS_EN.email.close)}
            </SecondaryButton>
            <PrimaryButton type="submit">
              {t('email.send', TRANSLATIONS_EN.email.send)}
            </PrimaryButton>
          </ModalButtons>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};



// Header Component
const Header = ({ onLogin, onLogout }) => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, currentUser } = useContext(UserContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const getDashboardLink = () => {
    if (!currentUser || !currentUser.role) return '/';
    switch (currentUser.role.toLowerCase()) {
      case 'administrator': return '/admin-dashboard';
      case 'manager': return '/manager-dashboard';
      case 'supervisor': return '/supervisor-dashboard';
      case 'technician': return '/technician-dashboard';
      default: return '/';
    }
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <HeaderContainer>
      <LogoTitleContainer>
        <Link to={isLoggedIn ? getDashboardLink() : '/'}>
          <Logo src={`${process.env.PUBLIC_URL}/rciLogo.png`} alt="RCI Logo" />
        </Link>
        <HeaderTitle>RCI Service NOW!</HeaderTitle>
      </LogoTitleContainer>
      <ButtonContainer>
        <HeaderButton onClick={toggleLanguage}>
          {i18n.language === 'en' ? 'Español' : 'English'}
        </HeaderButton>
        {!isLoggedIn ? (
          <>
            <Link to="/login"><HeaderButton>{t('Login')}</HeaderButton></Link>
            <Link to="/sign-up"><HeaderButton>{t('Sign Up')}</HeaderButton></Link>
          </>
        ) : (
          <>
            <HeaderButton onClick={handleLogout}>{t('Logout')}</HeaderButton>
            <IconButton onClick={toggleModal} aria-label="Request Assistance">
              <FaQuestionCircle />
            </IconButton>
          </>
        )}
      </ButtonContainer>
      {isModalOpen && <EmailModal onClose={toggleModal} />}
    </HeaderContainer>
  );
};

export default Header;
