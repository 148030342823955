import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '../../components/Firebase Functions/firebase';
import EditJob from './Edit Job/EditJob';
import EmailSender from './../Email Functions/Email Sender/EmailSender';
import './jobDetails.css';


const JobDetails = () => {
  const { id } = useParams();  // Get job ID from URL
  const navigate = useNavigate();
  const [jobData, setJobData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const storage = getStorage();

  useEffect(() => {
    const jobRef = doc(db, 'Services', id);

    // Listen for real-time updates to this specific job
    const unsubscribe = onSnapshot(jobRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const job = docSnapshot.data();

        // Process services field to ensure it's an array
        const servicesArray = typeof job.services === 'string' 
          ? job.services.split(',').map(s => s.trim())
          : Array.isArray(job.services) ? job.services : [];

        // Update image URLs if they exist
        const updatedImageUrls = {};
        if (job.imageUrls) {
          for (const [key, path] of Object.entries(job.imageUrls)) {
            if (path) {
              try {
                const imageRef = ref(storage, path);
                const url = await getDownloadURL(imageRef);
                updatedImageUrls[key] = url;
              } catch (error) {
                console.error(`Error loading image ${key}:`, error);
                updatedImageUrls[key] = null;
              }
            }
          }
        }

        setJobData({
          ...job,
          id,
          services: servicesArray,
          imageUrls: updatedImageUrls
        });
      }
      setLoading(false);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [id]);

  const handleSave = async (updatedData) => {
    try {
      const docRef = doc(db, 'Services', id);
      await updateDoc(docRef, {
        ...updatedData,
        services: updatedData.services.join(', ')
      });
      setIsEditing(false);  // Exit edit mode
    } catch (error) {
      console.error("Error saving job data:", error);
    }
  };

  const handleEmailClick = () => {
    setIsEmailModalOpen(true);
  };

  if (loading) return <div>Loading...</div>;
  if (!jobData) return <div>No data found for this job.</div>;

  return (
    <div className="job-details-container">
      <div className="job-details-header">
        <h1>Job Details</h1>
        <button onClick={() => navigate('/job-list')} className="back-button">
          ← Back to Services Needing Approval
        </button>

      </div>
  
      {isEditing ? (
        <EditJob 
          jobData={jobData}
          onSave={handleSave}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <>
          <div className="details-section">
            <p><strong>Supervisor:</strong> {jobData.supervisorName}</p>
            <p><strong>Property:</strong> {jobData.property || jobData.customPropertyName}</p>
            <p><strong>Services:</strong> {jobData.services.join(', ')}</p>
            <p className="job-date"><strong>Date:</strong> {jobData.date}</p>
            <p className="notes-section"><strong>Notes:</strong> {jobData.notes}</p>
  
            {/* Image Grid */}
            <div className="image-grid">
              {jobData.imageUrls && Object.entries(jobData.imageUrls).map(([key, url]) => (
                url ? (
                  <div key={key} className="image-container">
                    <img 
                      src={url} 
                      alt={`Job image ${key}`} 
                      className="job-image"  // Add class to align with .image-container styling
                    />
                  </div>
                ) : null
              ))}
            </div>
          </div>
  
          {/* Action Buttons */}
          <div className="job-details-buttons">
            <button className="send-email-button" onClick={handleEmailClick}>Send Email</button>
            <button className="edit-job-button" onClick={() => setIsEditing(true)}>Edit Job</button>
          </div>
        </>
      )}
  
      {isEmailModalOpen && (
        <EmailSender
          selectedJob={jobData}  // Pass current job data to EmailSender
          onClose={() => setIsEmailModalOpen(false)}
        />
      )}
    </div>
  );
  
};

export default JobDetails;