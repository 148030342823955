import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropertyList from '../QB Lists/PropertyList';
import { format } from 'date-fns';
import { doc, setDoc, collection, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../Firebase Functions/firebase';
import AMEmailSender from '../Email Functions/Email Sender/AMEmailSender';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ServiceCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 0.5rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
    width: 100%;
  }
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
    margin-left: 0;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`;

const services = [
  'Turf Mow', 'String Trim/Edging', 'Blow/Cleanup', 'Irrigation Monitoring and Control',
  'Mulch Install', 'Annuals Install', 'Tree Pruning', 'Palm Pruning', 'Medjool Seedhead Pruning',
  'Remediation', 'Seasonal Color Watering', 'Shrub Pruning', 'Pond Maintenance', 'Other'
];

const JobForm = ({ initialData }) => {
  const { t } = useTranslation();
  const { id: jobIdFromParams } = useParams();
  const [jobId, setJobId] = useState(jobIdFromParams || null);
  const [formData, setFormData] = useState({
    supervisorName: '',
    property: '',
    serviceTypes: [],
    customServiceDescription: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    notes: '',
    image: null,
    secondImage: null,
    thirdImage: null,
    fourthImage: null,
    fifthImage: null,
    customPropertyName: '',
    truckNumber: '',  // New field for Truck Number
    truckDriver: ''   // New field for Truck Driver
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEmailSenderOpen, setEmailSenderOpen] = useState(false);
  const [submittedJobData, setSubmittedJobData] = useState(null);
  const isEditing = !!initialData;

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData(prevState => ({
        ...prevState,
        date: format(new Date(), 'yyyy-MM-dd')
      }));
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked :
              type === 'file' ? files[0] :
              value
    }));
  };

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      serviceTypes: checked
        ? [...prevState.serviceTypes, value]
        : prevState.serviceTypes.filter(service => service !== value)
    }));
  };

  const handleRemoveImage = (imageName) => {
    setFormData(prevState => ({ ...prevState, [imageName]: null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!formData.property) {
        setError('Please select a property.');
        return;
    }

    try {
        const storage = getStorage();
        let imageUrls = {};

        // If editing, retrieve the existing document to retain previous image URLs
        if (isEditing && jobId) {
            const docRef = doc(db, "Services", jobId);
            const existingDoc = await getDoc(docRef);
            if (existingDoc.exists()) {
                imageUrls = existingDoc.data().imageUrls || {};
            }
        }

        // Upload new images if a file is selected
        for (const imageName of ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage']) {
            if (formData[imageName] && formData[imageName] instanceof File) {
                const imageRef = ref(storage, `images/${jobId || 'new'}/${imageName}-${Date.now()}`);
                await uploadBytes(imageRef, formData[imageName]);
                const downloadURL = await getDownloadURL(imageRef);
                imageUrls[imageName] = downloadURL;  // Add the new URL to imageUrls
            } else if (formData[imageName] && typeof formData[imageName] === 'string') {
                // Retain the existing URL if it's a string (previously uploaded)
                imageUrls[imageName] = formData[imageName];
            }
        }

        // Prepare the data to store in Firestore
        const firebaseData = {
            supervisorName: formData.supervisorName,
            property: formData.property,
            customPropertyName: formData.customPropertyName,
            services: formData.serviceTypes.join(', '),
            customServiceDescription: formData.serviceTypes.includes('Other') ? formData.customServiceDescription : null,
            notes: formData.notes,
            date: formData.date,
            imageUrls: imageUrls,  // Use the updated or existing image URLs
            truckNumber: formData.truckNumber,
            truckDriver: formData.truckDriver,
            ...(isEditing ? {} : { createdAt: serverTimestamp() })
        };

        let newJobId;

        if (isEditing && jobId) {
            // Update existing document
            await updateDoc(doc(db, "Services", jobId), firebaseData);
            newJobId = jobId;
            setSuccessMessage('Job updated successfully in Firebase!');
        } else {
            // Create a new document
            const newDocRef = doc(collection(db, "Services"));
            await setDoc(newDocRef, firebaseData);
            newJobId = newDocRef.id;
            setJobId(newJobId);  // Update the jobId for future edits
            setSuccessMessage('Job submitted successfully to Firebase!');
        }

        // Prepare job data for email
        const jobDataForEmail = {
            supervisorName: formData.supervisorName,
            id: newJobId,
            property: formData.property,
            date: formData.date,
            serviceTypes: formData.serviceTypes,
            customServiceDescription: formData.customServiceDescription,
            notes: formData.notes,
            imageUrls: Object.values(imageUrls).filter(Boolean),
        };

        if (jobDataForEmail.id && jobDataForEmail.property && jobDataForEmail.date) {
            setSubmittedJobData(jobDataForEmail);
            setEmailSenderOpen(true);
        } else {
            console.error("Incomplete job data:", jobDataForEmail);
            setError("Job data is incomplete or missing required fields.");
        }

        // Reset the form if it's a new submission
        if (!isEditing) {
            setFormData({
                supervisorName: '',
                property: '',
                serviceTypes: [],
                customServiceDescription: '',
                date: format(new Date(), 'yyyy-MM-dd'),
                notes: '',
                image: null,
                secondImage: null,
                thirdImage: null,
                fourthImage: null,
                fifthImage: null,
                customPropertyName: '',
                truckNumber: '',
                truckDriver: ''
            });
        }

    } catch (error) {
        console.error('Error submitting job:', error);
        setError('An error occurred while submitting the form. Please try again.');
    }
};



  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="supervisorName"
          value={formData.supervisorName}
          onChange={handleChange}
          placeholder={t('Supervisor Name')}
          required
        />
        <Input
          type="text"
          name="truckNumber"
          value={formData.truckNumber}
          onChange={handleChange}
          placeholder={t('Truck Number')}/>
           <Input
          type="text"
          name="truckDriver"
          value={formData.truckDriver}
          onChange={handleChange}
          placeholder={t('Truck Driver')}/>
         <Input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />
        <PropertyList 
          onSelect={(property) => 
            setFormData(prev => ({ ...prev, property: property["Property Name"] }))
          }
          required
        />
        <Input
          type="text"
          name="customPropertyName"
          value={formData.customPropertyName}
          onChange={handleChange}
          placeholder={t('Custom Property Name')}
        />
        {services.map(service => (
          <ServiceCheckbox key={service}>
            <input
              type="checkbox"
              id={service}
              name="serviceTypes"
              value={service}
              checked={formData.serviceTypes.includes(service)}
              onChange={handleServiceChange}
            />
            <CheckboxLabel htmlFor={service}>{t(service)}</CheckboxLabel>
          </ServiceCheckbox>
        ))}
        {formData.serviceTypes.includes('Other') && (
          <Input
            type="text"
            name="customServiceDescription"
            value={formData.customServiceDescription}
            onChange={handleChange}
            placeholder={t('If Services Not Listed, Add Them Here')}
            required
          />
        )}
        <Input
          type="text"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          placeholder={t('Additional Notes')}
        />
        {['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].map((imageName) => (
          <div key={imageName}>
            <Input
              type="file"
              name={imageName}
              onChange={handleChange}
              accept="image/*"
            />
            {formData[imageName] && (
              <ImagePreview>
                <img
                  src={formData[imageName] instanceof File ? URL.createObjectURL(formData[imageName]) : formData[imageName]}
                  alt={`Preview ${imageName}`}
                  height="50"
                />
                <RemoveButton onClick={() => handleRemoveImage(imageName)}>
                  Remove
                </RemoveButton>
              </ImagePreview>
            )}
          </div>
        ))}
        
       
        
        <Button type="submit">{isEditing ? t('Update Job') : t('Submit Job')}</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
      </Form>

      {isEmailSenderOpen && (
        <>
          <ModalOverlay onClick={() => setEmailSenderOpen(false)} />
          <ModalContent>
            <AMEmailSender
              jobId={jobId}
              job={submittedJobData}
              onClose={() => setEmailSenderOpen(false)}
            />
          </ModalContent>
        </>
      )}
    </>
  );
};

export default JobForm;
