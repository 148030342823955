import axios from 'axios';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

const quickbaseApi = axios.create({
    baseURL: 'https://api.quickbase.com/v1',
    headers: {
        'QB-Realm-Hostname': process.env.REACT_APP_QB_REALM_HOSTNAME,
        'Authorization': `QB-USER-TOKEN ${process.env.REACT_APP_QB_API_KEY}`,
        'Content-Type': 'application/json'
    }
});

const UpdateQuickBaseComponent = async (jobId, pdfBase64) => {
    try {
        // Step 1: Fetch job data from the Services table in Firebase
        const docRef = doc(db, 'Services', jobId);
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
            throw new Error('Firebase document not found.');
        }

        // Extract data from Services document
        const {
            property,
            supervisorName,
            services,
            customServiceDescription,
            notes,
            truckDriver,
            truckNumber,
        } = docSnapshot.data();

        // Step 2: Query the Properties table to find the matching Record ID#
        const propertiesRef = collection(db, 'Properties');
        const propertyQuery = query(propertiesRef, where('Property Name', '==', property));
        const propertySnapshot = await getDocs(propertyQuery);

        if (propertySnapshot.empty) {
            throw new Error('No matching property found in the Properties table.');
        }

        // Assume the first match is the desired property and extract `Record ID#`
        const propertyData = propertySnapshot.docs[0].data();
        const recordId = propertyData['Record ID#']; // Ensure this matches exactly how it's stored in Firebase

        // Step 3: Prepare the payload for QuickBase
        const serviceTypes = services ? services.split(',').map(service => service.trim()) : [];

        const data = {
            to: 'buiiqevgk', // QuickBase table ID
            data: [
                {
                      7: { value: supervisorName || 'N/A' }, // Supervisor Name
                     91: { value: serviceTypes.join(' ; ') }, // Services as a joined string
                     10: { value: notes || '' }, // Service Notes
                     39: { value: recordId || 0 }, // Use Record ID# from Properties table
                     35: { value: customServiceDescription },
                    100: { value: truckNumber || 'N/A' }, // Truck Number
                    101: { value: truckDriver || 'N/A' }, // Truck Driver
                     93: { // Field ID 93 for PDF attachment
                        value: {
                            fileName: `${property || 'Job'}_report.pdf`,
                            data: pdfBase64
                        }
                    }
                }
            ]
        };

        // Service Field Mapping and Dynamic Field Assignment
        const serviceFieldMapping = {
            'Turf Mow': 53,
            'String Trim/Edging': 54,
            'Blow/Cleanup': 55,
            'Irrigation Monitoring and Control': 56,
            'Hand Weeding': 57,
            'Litter Control': 58,
            'Mulch Install': 59,
            'Annuals Install': 60,
            'Tree Pruning': 61,
            'Palm Pruning': 62,
            'Medjool Seedhead Pruning': 63,
            'Remediation': 64,
            'Seasonal Color Watering': 65,
            'Shrub Pruning': 66,
            'Pond Maintenance': 67,
            'Other': 68
        };

        serviceTypes.forEach(service => {
            const fieldId = serviceFieldMapping[service];
            if (fieldId) {
                data.data[0][fieldId] = { value: true };
            }
        });

        // API call to QuickBase
        const response = await quickbaseApi.post('/records', data);
        console.log('QuickBase update successful:', response.data);

        if (response.data && response.data.metadata && response.data.metadata.createdRecordIds) {
            return { data: response.data, createdRecordId: response.data.metadata.createdRecordIds[0] };
        } else {
            throw new Error('Unexpected QuickBase response format.');
        }

    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
        console.error('Error updating QuickBase:', errorMessage);
        throw new Error(errorMessage);
    }
};

export default UpdateQuickBaseComponent;
