import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../components/Firebase Functions/firebase';
import './Job List/jobliststyles.css';

export function ServiceList() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentUserName, setCurrentUserName] = useState('');

    useEffect(() => {
        const fetchUserAndServices = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                
                if (!currentUser) {
                    console.error("No user logged in");
                    return;
                }

                // Get all users from Users collection
                const usersRef = collection(db, 'Users');
                const usersSnapshot = await getDocs(usersRef);
                
                // Find the current user's document by matching email
                const currentUserDoc = usersSnapshot.docs.find(
                    doc => doc.data().email === currentUser.email
                );

                if (currentUserDoc) {
                    const userData = currentUserDoc.data();
                    const fullName = `${userData.first_name.trim()} ${userData.last_name.trim()}`;
                    console.log("Current user's full name:", fullName);
                    setCurrentUserName(fullName);

                    // Fetch all services
                    const servicesRef = collection(db, 'Services');
                    const servicesSnapshot = await getDocs(servicesRef);

                    const servicesData = servicesSnapshot.docs.map(doc => {
                        const data = doc.data();
                        let services = data.services;
                        if (typeof services === 'string' && services.includes(',')) {
                            services = services.split(',').map(s => s.trim());
                        }
                        services = Array.isArray(services) ? services : [services].filter(Boolean);

                        return {
                            id: doc.id,
                            ...data,
                            services
                        };
                    });

                    // Filter services where supervisorName matches the user's full name
                    const filteredServices = servicesData.filter(service => {
                        console.log("Comparing supervisorName:", service.supervisorName, "with fullName:", fullName);
                        return service.supervisorName === fullName && 
                               !('sentToCustomer' in service);
                    });

                    console.log("Filtered services:", filteredServices);
                    setServices(filteredServices);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserAndServices();
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    if (loading) return <div>Loading...</div>;
    if (services.length === 0) return <div>No services available.</div>;

    return (
        <div className="job-list-container">
            <h2>Available Services</h2>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search services"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>

            <div className="job-grid">
                {services
                    .filter(service => service.property?.toLowerCase().includes(searchQuery.toLowerCase()))
                    .map(service => (
                        <div key={service.id} className="job-card">
                            <div className="job-header">
                                <h3>{service.property || 'Unnamed Property'}</h3>
                                <p className="date">Supervisor: {service.supervisorName || 'Unknown'}</p>
                            </div>
                            <div className="services-section">
                                <h4>Services Performed:</h4>
                                <ul>
                                    {Array.isArray(service.services) && service.services.length > 0 
                                        ? service.services.map((srv, index) => (
                                            <li key={index}>{srv}</li>
                                          ))
                                        : <li>No services performed</li>}
                                </ul>
                            </div>
                            <div className="notes-section">
                                <h4>Notes:</h4>
                                <p>{service.notes || 'No additional notes.'}</p>
                            </div>

                            <div className="image-grid">
                                {service.imageUrls ? (
                                    ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].map((key) => {
                                        const imageUrl = service.imageUrls[key];
                                        return imageUrl ? (
                                            <div key={`view-${service.id}-${key}`} className="image-container">
                                                <img
                                                    src={imageUrl}
                                                    alt={`Job for ${service.property}`}
                                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                />
                                            </div>
                                        ) : null;
                                    })
                                ) : (
                                    <p>No images available.</p>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default ServiceList;