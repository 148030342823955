import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, deleteDoc} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '../../../components/Firebase Functions/firebase';
import './jobliststyles.css';
import EmailSender from '../../Email Functions/Email Sender/EmailSender';
import EditJob from '../Edit Job/EditJob';

function JobList() {
    const navigate = useNavigate();
    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedJobData, setSelectedJobData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const imageCacheRef = useRef({}); // Cache previously loaded image URLs

    const isValidJobData = (jobData) => {
        return (
            jobData &&
            jobData.property &&
            jobData.date &&
            Array.isArray(jobData.services)
        );
    };

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Services'));
                const jobsDataArray = [];
                const storage = getStorage();

                for (const document of querySnapshot.docs) {
                    const jobData = document.data();

                    if (jobData.sentToCustomer) continue;

                    const servicesArray = Array.isArray(jobData.services)
                        ? jobData.services
                        : typeof jobData.services === 'string'
                            ? jobData.services.split(',').map(s => s.trim())
                            : [];

                    // Fetch or cache image URLs
                    const imageUrls = await fetchCachedImageUrls(document.id, jobData.imageUrls || {});

                    jobsDataArray.push({
                        id: document.id,
                        ...jobData,
                        property: jobData.property || 'Unnamed Property',
                        services: servicesArray,
                        imageUrls,
                    });
                }
                setJobsData(jobsDataArray);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    const fetchCachedImageUrls = async (jobId, currentImageUrls) => {
        const storage = getStorage();
        const freshImageUrls = { ...currentImageUrls };

        for (const [key, oldUrl] of Object.entries(currentImageUrls)) {
            if (imageCacheRef.current[jobId]?.[key]) {
                freshImageUrls[key] = imageCacheRef.current[jobId][key];
                continue;
            }

            if (typeof oldUrl === 'string' && oldUrl) {
                try {
                    const path = decodeURIComponent(
                        oldUrl.split('/o/')[1].split('?')[0].replace(/%2F/g, '/')
                    );
                    const imageRef = ref(storage, path);
                    const freshUrl = await getDownloadURL(imageRef);

                    // Cache fresh URL and use it
                    if (!imageCacheRef.current[jobId]) {
                        imageCacheRef.current[jobId] = {};
                    }
                    imageCacheRef.current[jobId][key] = freshUrl;
                    freshImageUrls[key] = freshUrl;
                } catch (error) {
                    console.error(`Error fetching URL for ${key}:`, error);
                    freshImageUrls[key] = oldUrl;
                }
            } else {
                freshImageUrls[key] = oldUrl;
            }
        }

        return freshImageUrls;
    };

    const handleSave = async (updatedJobData) => {
        try {
            const jobRef = doc(db, 'Services', updatedJobData.id);
            await updateDoc(jobRef, updatedJobData);

            // Fetch updated image URLs if they've been modified
            const updatedImageUrls = await fetchCachedImageUrls(updatedJobData.id, updatedJobData.imageUrls);

            // Update only the specific job's images without affecting other jobs
            setJobsData((prevJobsData) =>
                prevJobsData.map((jobData) => 
                    jobData.id === updatedJobData.id 
                        ? { ...updatedJobData, imageUrls: updatedImageUrls } 
                        : jobData
                )
            );

            setIsEditing(false);
            setSelectedJobData(null);
        } catch (error) {
            console.error("Error saving updated job:", error);
        }
    };

    const handleEmailClick = (jobData) => {
        if (isValidJobData(jobData)) {
            setSelectedJobData(jobData);
            setIsEmailModalOpen(true);
        } else {
            alert("Unable to send email: Job data is incomplete or invalid.");
        }
    };

    const handleDelete = async (jobId) => {
        if (window.confirm('Are you sure you want to delete this job? This action cannot be undone.')) {
            try {
                await deleteDoc(doc(db, 'Services', jobId));
                
                // Update local state to remove the deleted job
                setJobsData(prevJobs => prevJobs.filter(job => job.id !== jobId));
            } catch (error) {
                console.error("Error deleting job:", error);
                alert('Failed to delete job. Please try again.');
            }
        }
    };

    const handleEditClick = (jobData) => {
        setSelectedJobData(jobData);
        setIsEditing(true);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredJobsData = jobsData
    .filter((jobData) => {
        const searchTerms = searchQuery.split(' ').filter((term) => term);
        return (
            searchTerms.length === 0 ||
            searchTerms.every((term) =>
                jobData.property.toLowerCase().includes(term) ||
                jobData.services.some((service) => service.toLowerCase().includes(term)) ||
                (jobData.date && jobData.date.toLowerCase().includes(term))
            )
        );
    })
    .sort((a, b) => (b.createdAt?.toMillis() || 0) - (a.createdAt?.toMillis() || 0));


    

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="job-list-container">
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by property, service, or date..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>

            <div className="job-grid">
                {jobsData.map((jobData) => (
                    <div key={jobData.id} className="job-card">
                        <div className="job-header">
                            <h3>{jobData.property}</h3>
                            <p>{jobData.supervisorName}</p>
                            <p className="date">{jobData.date}</p>
                        </div>

                        {jobData.imageUrls && Object.entries(jobData.imageUrls).length > 0 && (
                            <div className="image-grid">
                                {Object.entries(jobData.imageUrls).map(([key, url]) => (
                                    url && (
                                        <div key={key} className="image-container">
                                            <img src={url} alt={`${key} view`} />
                                        </div>
                                    )
                                ))}
                            </div>
                        )}

                        <div className="services-section">
                            <h4>Services:</h4>
                            <ul>
                                {jobData.services.map((service, index) => (
                                    <li key={index}>{service}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="notes-section">
                            <h4>Notes:</h4>
                            <p>{jobData.notes || 'No notes available'}</p>
                        </div>

                        <div className="button-container">
                            <button
                                onClick={() => handleEmailClick(jobData)}
                                className="email-button"
                            >
                                Send Email
                            </button>
                            <button onClick={() => navigate(`/jobs/${jobData.id}`)} className="view-details-button">
                                View Details
                            </button>
                            <button
                                onClick={() => handleDelete(jobData.id)}
                                className="delete-button"
                            >
                                Delete Job
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Email Modal */}
            {isEmailModalOpen && selectedJobData && (
                <div className="modal-overlay" onClick={() => setIsEmailModalOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <EmailSender
                            selectedJob={selectedJobData}
                            onClose={() => {
                                setIsEmailModalOpen(false);
                                setSelectedJobData(null);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default JobList;
