import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;


const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;

    @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;


const LoginInput = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;

   @media (max-width: 768px) {
    font-size: 16px; // Prevents zoom on focus in iOS
    padding: 0.75rem;
  }
`;


const LoginButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

    @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
  }
`;


const SignUpLink = styled.p`
  margin-top: 1rem;
  text-align: center;

   @media (max-width: 768px) {
    font-size: 14px;
  }
`;


const SignUpButton = styled.button`
  background: none;
  border: none;
  color: #27ae60;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0.5rem;
  }
`;

const ForgotPasswordButton = styled.button`
  background: none;
  border: none;
  color: #27ae60;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  margin-top: 0.5rem;

    @media (max-width: 768px) {
    font-size: 14px;
    padding: 0.5rem;
    margin-top: 0.75rem;
  }
`;

const RememberMeCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

    @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;

   @media (max-width: 768px) {
    transform: scale(1.2); // Slightly larger checkbox for easier tapping
  }
`;

const Login = ({ onLogin, onSignUpClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [setResetMessage] = useState('');
  const [error, setError] = useState('');
  const { handleLogin } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there are saved credentials in local storage
    const savedEmail = localStorage.getItem('rememberedEmail');
    const savedPassword = localStorage.getItem('rememberedPassword');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
    
    if (savedEmail && savedPassword && savedRememberMe) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Fetch the user's document from Firestore
      const userDoc = await getDoc(doc(db, "Users", user.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        
        // Handle "Remember Me" functionality
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', email);
          localStorage.setItem('rememberedPassword', password);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('rememberedEmail');
          localStorage.removeItem('rememberedPassword');
          localStorage.removeItem('rememberMe');
        }
        
        // Create a user object with role information
        const userInfo = {
          uid: user.uid,
          email: user.email,
          role: userData.type // Assuming 'type' field in Firestore represents the user role
        };

        // Call handleLogin from UserContext to update global state
        handleLogin(userInfo);
        
        // Call onLogin prop function
        onLogin(userInfo);
  
        // Navigate based on user role
        if (userData.type === 'manager') {
          navigate('/manager-dashboard');
        } else if (userData.type === 'supervisor') {
          navigate('/supervisor-dashboard');
        } else {
          navigate('/home'); // Default route for other user types
        }
      } else {
        setError('User data not found. Please contact support.');
      }
    } catch (error) {
      console.error("Login error:", error);
      if (error.code === 'auth/user-not-found') {
        setError('No user found with this email. Please check your email or sign up.');
      } else if (error.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address. Please enter a valid email.');
      } else if (error.code === 'auth/user-disabled') {
        setError('This account has been disabled. Please contact support.');
      } else {
        setError('An error occurred during login. Please try again later.');
      }
    }
  };


  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
    if (!e.target.checked) {
      // Clear saved credentials if "Remember Me" is unchecked
      localStorage.removeItem('rememberedEmail');
      localStorage.removeItem('rememberedPassword');
      localStorage.removeItem('rememberMe');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent. Please check your inbox.');
      setError('');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      setResetMessage('');
    }
  };

  return (
    <LoginContainer>
      <h2>{t('Login')}</h2>
      <LoginForm onSubmit={handleSubmit}>
        <LoginInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <LoginInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <RememberMeCheckbox>
          <Checkbox
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
          />
          <label>{t('Remember Me')}</label>
        </RememberMeCheckbox>
        <LoginButton type="submit">{t('Login')}</LoginButton>
        <ForgotPasswordButton type="button" onClick={handleForgotPassword}>
          {t('Forgot Password?')}
        </ForgotPasswordButton>
      </LoginForm>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <SignUpLink>
        {t("Don't have an account? ")}
        <SignUpButton
          type="button"
          onClick={() => navigate('/sign-up')}>
          {t('Sign up')}
        </SignUpButton>
      </SignUpLink>
    </LoginContainer>
  );
};

export default Login;