import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from '../../Firebase Functions/firebase';
import './email-sender-styles.css';

function AMEmailSender({ job, onClose, jobId }) {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        setMessage('');
        if (job?.property) {
          const propertiesRef = collection(db, 'Properties');
          const q = query(propertiesRef, where("Property Name", "==", job.property));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const propertyDoc = querySnapshot.docs[0];
            const propertyData = propertyDoc.data();
            let amEmails = propertyData["AM Email List"] || "";
            let rmEmails = propertyData["RM Email List"] || "";
            let emailList = new Set([
              ...amEmails.split(',').map(email => email.trim()),
              ...rmEmails.split(',').map(email => email.trim())
            ].filter(Boolean));

            if (emailList.size === 0) {
              const dmEmails = propertyData["DM Email List"];
              if (dmEmails) {
                dmEmails.split(',').forEach(email => emailList.add(email.trim()));
              }
            }

            setTo(Array.from(emailList).join(', ') || 'No available emails');
          } else {
            setMessage("No document found for the provided Property Name.");
          }
        } else {
          setMessage("No property name provided in job.");
        }
      } catch (error) {
        setMessage('Failed to retrieve emails due to an error.');
      }
    };

    fetchEmails();
  }, [job?.property]);

  if (!jobId) {
    console.error("Job or jobId is undefined in job object");
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('Sending email...');

    try {
      if (!to) {
        setMessage('Please select a recipient.');
        return;
      }

      const jobLink = `https://rciservicenow.com/jobs/${jobId}`;
      const emailData = {
        to: to.split(',').map(email => email.trim()),
        subject: `Approval Needed for a New Service Update for ${job.property || 'Unnamed Property'}`,
        text: `Please find the job report for ${job.property || 'Unnamed Property'}.\n\nYou can view the full report here: ${jobLink}`,
        html: `<p>Please find the job report for ${job.property || 'Unnamed Property'}.</p><p>You can view the full report <a href="${jobLink}">here</a>.</p>`
      };

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailData })
      });

      if (response.ok) {
        setMessage('Email sent successfully!');
        setTimeout(onClose, 2000);
      } else {
        const errorData = await response.json();
        setMessage(`Failed to send email: ${errorData.error || 'Unknown error'}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }

    navigate('/supervisor-dashboard');
  };

  return (
    <div className="email-modal">
      <div className="email-modal-content">
        <button className="close-modal" onClick={onClose}>&times;</button>
        <h2>Send Email</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <p>{to ? `Recipients: ${to}` : 'Loading recipients...'}</p>
          </div>
          <div className="button-group">
            <button type="submit" className="primary-button">Send Email</button>
            <button type="button" className="secondary-button" onClick={onClose}>Cancel</button>
          </div>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default AMEmailSender;
