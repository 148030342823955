import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase Functions/firebase';
import styled from 'styled-components';
import { doc, setDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const SignUpForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const SignUpInput = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const SignUpButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`;

const StyledLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: inherit;
`;

const SignUp = ({ onSignUp, onLoginClick }) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [type] = useState('');
  const { t } = useTranslation();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "Users", user.uid), {
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        type,
      });

      onSignUp();
      navigate('/home');
    } catch (error) {
      setError('Failed to create an account. ' + error.message);
    }
  };

  return (
    <SignUpForm onSubmit={handleSignUp}>
      <SignUpContainer>
        <h2>{t('Create an Account')}</h2>
        <SignUpInput
          type="email"
          placeholder={t("Email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder={t("First Name")}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder={t("Last Name")}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <SignUpInput
          type="tel"
          placeholder={t("Phone")}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder={t("Password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder={t("Confirm Password")}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <SignUpButton type="submit">{t('Sign up')}</SignUpButton>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        
        <p>
          {t('Already have an account?')} 
          <StyledLinkButton type="button" onClick={onLoginClick}>
            {t('Login')}
          </StyledLinkButton>
        </p>
      </SignUpContainer>
    </SignUpForm>
  );
};

export default SignUp;
