// src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import only the Spanish translations
import es from './locales/es.json';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      es: { translation: es } // Only Spanish translations
    },
    lng: 'en', // Default language is English, without an `en.json` file
    fallbackLng: 'en', // Fallback to English if a translation is missing
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    }
  });

export default i18n;
