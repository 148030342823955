import React, { useState, useEffect, useCallback } from 'react';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { JobPDF } from '../../PDF Functions/PDFGenerator';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase Functions/firebase';
import './email-sender-styles.css';
import { getStorage, ref, getBytes } from 'firebase/storage';
import Compressor from 'compressorjs';
import UpdateQuickBaseComponent from '../../Quickbase Functions/quickBaseUtils';

function EmailSender({ selectedJob, onClose }) {
    const [message, setMessage] = useState('');
    const [pdfBlob, setPdfBlob] = useState(null);
    const [emailAlreadySent, setEmailAlreadySent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageBase64, setImageBase64] = useState([]);
    const [to, setTo] = useState('');
    const [cc, setCc] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState(
        `Dear Valued Customer,\n\nWe wanted to extend our heartfelt thanks for trusting RCI with your landscaping needs...`
    );

    const [emailConfig] = useState({
        isConfigured: true,
        senderEmail: 'serviceupdate@rotoloconsultants.com',
        emailLimits: {
            maxSubjectLength: 100,
            maxBodyLength: 5000
        }
    });

    useEffect(() => {
        if (selectedJob) {
            const jobDate = selectedJob.date || 'Unknown Date';
            const propertyName = selectedJob.property || 'Unnamed Property';
            setSubject(`Service Update for ${propertyName} on ${jobDate}`);
        }
    }, [selectedJob]);

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                }
            });
        });
    };

    const checkIfEmailSent = useCallback(async () => {
        if (!selectedJob) return;
        try {
            const docRef = doc(db, 'Services', selectedJob.id);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists() && docSnapshot.data().sentToCustomer) {
                setEmailAlreadySent(true);
                setMessage('Email has already been sent to this customer.');
            }
        } catch (error) {
            console.error('Error checking email status:', error);
            setMessage('Error checking email status.');
        }
    }, [selectedJob]);

    const fetchEmails = async () => {
        try {
            if (!selectedJob || !selectedJob.property) {
                throw new Error("Property name is missing.");
            }
            const propertiesRef = collection(db, 'Properties');
            const propertyQuery = query(propertiesRef, where("Property Name", "==", selectedJob.property));
            const querySnapshot = await getDocs(propertyQuery);

            if (querySnapshot.empty) {
                throw new Error("No document found for the provided Property Name.");
            }

            const propertyData = querySnapshot.docs[0].data();
            const amEmails = propertyData["AM Email List"]?.split(",").map(email => email.trim()) || [];
            const rmEmails = propertyData["RM Email List"]?.split(",").map(email => email.trim()) || [];
            const dmEmails = propertyData["DM Email List"]?.split(",").map(email => email.trim()) || [];

            setTo(amEmails.join(', '));
            const ccEmails = amEmails.length === 0 ? dmEmails : rmEmails;
            setCc(ccEmails.join(', '));

        } catch (error) {
            console.error("Error fetching emails:", error);
            setMessage(`Error fetching emails: ${error.message}`);
        }
    };

    const convertImagesToBase64 = async ({ imageUrls }) => {
        const validUrls = imageUrls.filter(url => url !== null);
        const base64Images = [];
        const storage = getStorage();

        for (const url of validUrls) {
            try {
                const pathMatch = url.match(/o\/(.+?)\?/);
                if (!pathMatch) continue;

                const path = decodeURIComponent(pathMatch[1]);
                const imageRef = ref(storage, path);
                const bytes = await getBytes(imageRef);
                const compressedImage = await compressImage(new Blob([bytes], { type: 'image/jpeg' }));

                const base64 = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(compressedImage);
                });

                base64Images.push(base64);
            } catch (error) {
                console.error('Error converting image to base64:', error);
            }
        }

        setImageBase64(base64Images);
        return base64Images;
    };

    const fetchImageUrlsAndConvert = async () => {
        try {
            if (!selectedJob) return [];
            const imageUrls = Object.values(selectedJob.imageUrls || {}).filter(Boolean);
            if (imageUrls.length === 0) {
                console.warn('No valid image URLs found');
                return [];
            }
            return await convertImagesToBase64({ imageUrls });
        } catch (error) {
            console.error('Error in fetchImageUrlsAndConvert:', error);
            setMessage(`Error converting images: ${error.message}`);
            return [];
        }
    };

    const handleGeneratePdf = async () => {
        setIsLoading(true);
        try {
            let currentBase64Images = imageBase64;
            if ((!currentBase64Images || currentBase64Images.length === 0) && selectedJob?.imageUrls) {
                currentBase64Images = await fetchImageUrlsAndConvert();
            }

            if (selectedJob) {
                const blob = await pdf(
                    <JobPDF formData={selectedJob} base64Images={currentBase64Images} />
                ).toBlob();
                setPdfBlob(blob);
            }
        } catch (error) {
            console.error("PDF generation error:", error);
            setMessage(error.message || "An error occurred while generating PDF.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedJob) {
            const initialize = async () => {
                try {
                    await fetchEmails();
                    await fetchImageUrlsAndConvert();
                    await handleGeneratePdf();
                    await checkIfEmailSent();
                } catch (error) {
                    console.error("Initialization error:", error);
                    setMessage(error.message || "An error occurred during initialization.");
                }
            };
            initialize();
        }
    }, [selectedJob, checkIfEmailSent]);

    const updateFirestoreDocument = async () => {
        try {
            const docRef = doc(db, 'Services', selectedJob.id);
            await updateDoc(docRef, { sentToCustomer: true });
        } catch (error) {
            console.error('Error updating Firestore document:', error);
            setMessage('Failed to update Firestore document.');
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Early exit if the email has already been sent
        if (emailAlreadySent) {
            setMessage('Email has already been sent to this customer.');
            return;
        }
    
        // Check if email service is properly configured
        if (!emailConfig?.isConfigured) {
            setMessage('Email service is not properly configured');
            return;
        }
    
        setIsLoading(true);
        setMessage('Sending email...');
    
        try {
            // Convert PDF Blob to base64
            const pdfBase64 = pdfBlob
                ? await new Promise((resolve) => {
                      const reader = new FileReader();
                      reader.onloadend = () => resolve(reader.result.split(',')[1]);
                      reader.readAsDataURL(pdfBlob);
                  })
                : null;
    
            // Prepare email data payload
            const emailData = {
                to: to.split(',').map(email => email.trim()),
                subject,
                text: body,
                html: body.replace(/\n/g, '<br>'),
                cc: cc ? cc.split(',').map(email => email.trim()) : undefined,
                from: emailConfig.senderEmail,
                attachments: pdfBase64
                    ? [{
                          content: pdfBase64,
                          filename: `${selectedJob?.property || 'Job'}_report.pdf`,
                          type: 'application/pdf',
                          disposition: 'attachment',
                      }]
                    : undefined,
            };
    
            // Send the email through your API
            const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ emailData }),
            });
    
            const data = await response.json();
            if (response.ok) {
                // Update Firestore only if email was sent successfully
                await updateFirestoreDocument();
                setEmailAlreadySent(true); // Mark it as sent in the local component state
                setMessage('Email sent successfully!');
    
                // Additional logic to update QuickBase or other services
                const editForm = {
                    supervisorName: selectedJob.supervisorName,
                    propertyId: selectedJob.propertyId,
                    serviceTypes: selectedJob.serviceTypes,
                    customServiceDescription: selectedJob.customServiceDescription,
                    date: selectedJob.date,
                    notes: selectedJob.notes,
                };
                await UpdateQuickBaseComponent(selectedJob.id, pdfBase64);
    
                // Close modal after a brief success message display
                setTimeout(onClose, 2000);
            } else {
                // Handle email API errors
                throw new Error(data.error || 'Unknown error occurred while sending email.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };
    
    

    return (
        <div className="email-modal">
            <div className="email-modal-content">
                <button className="close-modal" onClick={onClose}>×</button>
                <h2>Send Email</h2>
                <form onSubmit={handleSubmit}>
    <div className="form-group">
        <label htmlFor="to">To:</label>
        <input
            type="text"
            id="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            required
            className="form-control"
        />
    </div>
    <div className="form-group">
        <label htmlFor="cc">CC:</label>
        <input
            type="text"
            id="cc"
            value={cc}
            onChange={(e) => setCc(e.target.value)}
            className="form-control"
        />
    </div>
    <div className="form-group">
        <label htmlFor="subject">Subject:</label>
        <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            className="form-control"
            maxLength={emailConfig?.emailLimits?.maxSubjectLength || 100}
        />
    </div>
    <div className="form-group">
        <label htmlFor="body">Body:</label>
        <textarea
            id="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            className="form-control"
            rows="6"
            maxLength={emailConfig?.emailLimits?.maxBodyLength || 5000}
        />
    </div>
    <div className="form-group">
        <label>Attachment:</label>
        <p>{pdfBlob ? `${selectedJob?.property || 'Job'}_report.pdf` : 'No attachment'}</p>
        <PDFDownloadLink
            document={<JobPDF formData={selectedJob} base64Images={imageBase64} />}
            fileName={`${selectedJob?.property || 'job'}-report.pdf`}
            className="pdf-download-link"
        >
            {({ loading }) => (loading ? 'Loading PDF...' : 'Preview PDF')}
        </PDFDownloadLink>
    </div>
    <button
        type="submit"
        className={`primary-button ${isLoading ? 'disabled' : ''}`}
        disabled={isLoading || emailAlreadySent}
    >
        {emailAlreadySent ? 'Email Already Sent' : 'Send Email'}
    </button>
    <button type="button" className="secondary-button" onClick={onClose} disabled={isLoading}>
        Cancel
    </button>
</form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
}

export default EmailSender;
